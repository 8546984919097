"use client";
import React, { useState, useEffect } from "react";
import SwiperPage from "../SwiperPage/SwiperPage";
import backButton from "../../assets/signup/back-button.png";
import eye from "../../assets/signup/eye.png";
import tick from "../../assets/signup/tick-circle.png";
import yellowTick from "../../assets/signup/yellow-tick-circle.png";
import eyeSlash from "../../assets/signup/eye-slash.png";
import backgroundImage from "../../assets/swiper/backkground.png";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useRegister } from "@/apis/signup/mutations";
import { Bounce, toast } from "react-toastify";
import { usePhoneNumberOrEmail } from "@/zustand/usePhoneNumberOrEmail";
import { useModal } from "src/zustand/useModal";
import WebError from "../common/WebError";
import useWindowSize from "src/helper/useWindowSize";
import useLanguage from "src/context/useLanguage";
const SignUp = () => {
  const { translations, language } = useLanguage();
  const signUpTranslations = translations[language].login;
  const { setPhone } = usePhoneNumberOrEmail((state) => state);
  const { setOpenModal, setModal, setOpenModalWeb, setModalWeb } = useModal();
  const router = useRouter();
  const { width } = useWindowSize();
  // useEffect(() => {
  //   if (token?.length > 0) {
  //     router.push("/home");
  //   }
  // }, [router]);
  const { mutateAsync } = useRegister();
  const [agreed, setAgreed] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    referral_code: "",
    phone: "",
    email: "",
    password: "",
    repeatPassword: "",
    countryCode: "20",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const validateInput = (name, value) => {
    let errorMsg = "";

    if (name === "firstName" || name === "lastName") {
      if (value.trim().length < 3) {
        errorMsg = `${
          name === "firstName" ? "First" : "Last"
        } name must be at least 3 characters.`;
      }
    }

    if (name === "phone") {
      if (!/^\d+$/.test(value)) {
        errorMsg = "Phone number must contain only digits.";
      } else if (value.length > 11) {
        errorMsg = "Phone number cant be more than 11 digits.";
      }
    }

    if (name === "email") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        errorMsg = "Please enter a valid email address.";
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateInput(name, value); // Validate while typing
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setPhone(formData.phone.slice(1));
    let sentData = { ...formData };
    localStorage.setItem("phoneOrEmail", formData.phone.slice(1));
    localStorage.setItem("phoneOrEmailType", "phone");
    localStorage.setItem("phone", formData.phone.slice(1));
    if (sentData?.referral_code === "" || sentData?.referral_code === null) {
      delete sentData.referral_code;
    }
    // Submit the form if all validations pass
    await mutateAsync(sentData).catch((error) => {
      const validationErrors =
        error?.response?.data?.validation || error?.response?.data?.message;

      if (validationErrors) {
        // Extract the first validation error message
        let firstErrorMessage =
          typeof validationErrors === "string"
            ? validationErrors
            : Object.values(validationErrors)?.[0];
        if (
          firstErrorMessage ===
          '"referral_code" length must be 8 characters long'
        ) {
          firstErrorMessage = "Referral code must be 8 characters long";
        }
        if (firstErrorMessage === "invalid referral_code") {
          firstErrorMessage = "Invalid referral code";
        }
        if (firstErrorMessage === `"email" must be a valid email`) {
          firstErrorMessage = "Please enter a valid email address.";
        }
        if (width < 768) {
          // Display the error in the modal
          setModal({
            type: "warning", // Or any type you want
            header: "Validation Error",
            text: firstErrorMessage || "An unknown error occurred.",
            buttonText: "Close",
            onClick: () => setOpenModal(false),
          });
          setOpenModal(true);
        } else {
          // setModal({
          //   header: "Validation Error",
          //   text: firstErrorMessage || "An unknown error occurred.",
          // });
          setOpenModalWeb(true);
        }
      } else {
        console.error("An unexpected error occurred:", error);
      }
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="  flex flex-col relative h-full pt-12 flex-1"
    >
      <label className="text-subGrey text-xs mb-3">
        {signUpTranslations.first}{" "}
      </label>
      <input
        style={{
          boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012 `,
        }}
        type="text"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        className="shadow-sm   p-4 rounded-xl bg-white focus:border-mainYellow focus:border   mb-4 placeholder:text-[#E6E6E6]"
        placeholder={signUpTranslations.enterFirst}
      />
      {errors.firstName && (
        <div className="text-red-500 text-xs mb-3">{errors.firstName}</div>
      )}

      <label className="text-subGrey text-xs mb-3">
        {signUpTranslations.last}
      </label>
      <input
        style={{
          boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012 `,
        }}
        type="text"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        className="shadow-sm   p-4 rounded-xl bg-white focus:border-mainYellow focus:border   mb-4 placeholder:text-[#E6E6E6]"
        placeholder={signUpTranslations.enterLast}
      />
      {errors.lastName && (
        <div className="text-red-500 text-xs mb-3">{errors.lastName}</div>
      )}

      <label className="text-subGrey text-xs mb-3">
        {signUpTranslations.phoneNumber}
      </label>
      <input
        style={{
          boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012 `,
        }}
        type="number"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        className="shadow-sm   p-4 rounded-xl bg-white focus:border-mainYellow focus:border   mb-4 placeholder:text-[#E6E6E6]"
        placeholder="01xxxxxxxxx"
      />
      {errors.phone && (
        <div className="text-red-500 text-xs mb-3">{errors.phone}</div>
      )}

      <label className="text-subGrey text-xs mb-3">
        {signUpTranslations.email}
      </label>
      <input
        style={{
          boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012 `,
        }}
        type="text"
        name="email"
        value={formData.email}
        onChange={handleChange}
        className="shadow-sm   p-4 rounded-xl bg-white focus:border-mainYellow focus:border   mb-4 placeholder:text-[#E6E6E6]"
        placeholder="name@example.com"
      />
      {errors.email && (
        <div className="text-red-500 text-xs mb-3">{errors.email}</div>
      )}

      <label className="text-subGrey text-xs mb-3">
        {signUpTranslations.referralCode}
      </label>
      <input
        style={{
          boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012 `,
        }}
        type="text"
        name="referral_code"
        value={formData.referral_code}
        onChange={handleChange}
        className="shadow-sm   p-4 rounded-xl bg-white focus:border-mainYellow focus:border   mb-4 placeholder:text-[#E6E6E6]"
        placeholder="Ge588w46"
      />
      <div className="relative">
        <label className="text-subGrey text-xs ">
          {signUpTranslations.password}{" "}
        </label>
        <input
          style={{
            boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012 `,
          }}
          type={showPassword ? "text" : "password"}
          name="password"
          value={formData.password}
          onChange={handleChange}
          className="mt-2 shadow-sm   p-4 rounded-xl bg-white focus:border-mainYellow focus:border   mb-4 placeholder:text-[#E6E6E6] w-full"
          placeholder={signUpTranslations.enterPassword}
        />
        <span
          className={`
            absolute top-[57%]  transform -translate-y-1/2 cursor-pointer
                        ${language === "en" ? "right-4" : "left-4"}


            `}
          onClick={togglePasswordVisibility}
        >
          <Image
            src={showPassword ? eyeSlash : eye}
            alt="eye"
            width={20}
            height={20}
          />
        </span>
      </div>
      <div className="relative">
        <label className="text-subGrey text-xs ">
          {" "}
          {signUpTranslations.confirmPassword}
        </label>
        <input
          style={{
            boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012 `,
          }}
          type={showConfirmPassword ? "text" : "password"}
          name="repeatPassword"
          value={formData.repeatPassword}
          onChange={handleChange}
          className="mt-2 shadow-sm   p-4 rounded-xl bg-white focus:border-mainYellow focus:border   mb-4 placeholder:text-[#E6E6E6] w-full"
          placeholder={signUpTranslations.enterConfirmPassword}
        />
        <span
          className={`
            
            absolute top-[57%]  transform -translate-y-1/2 cursor-pointer
            ${language === "en" ? "right-4" : "left-4"}
            `}
          onClick={toggleConfirmPasswordVisibility}
        >
          <Image
            src={showConfirmPassword ? eyeSlash : eye}
            alt="eye"
            width={20}
            height={20}
          />
        </span>
      </div>

      <div className="grid grid-cols-2 sm:gap-28 text-nowrap gap-1 text-[0.625rem] md:text-sm  font-ibmArabic ">
        <div className="flex flex-col space-y-1">
          <p
            className={`flex items-center gap-2 ${
              formData?.password.trim().length >= 8
                ? "text-mainYellow"
                : "text-[#999999]"
            }`}
          >
            <Image
              src={formData?.password.trim().length >= 8 ? yellowTick : tick}
              alt="tick"
              width={16}
              height={16}
            />
            {signUpTranslations.length}
          </p>
          <p
            className={`flex items-center gap-2 ${
              formData?.password.match(/\d/)
                ? "text-mainYellow"
                : "text-[#999999]"
            }`}
          >
            <Image
              src={formData?.password.match(/\d/) ? yellowTick : tick}
              alt="tick"
              width={16}
              height={16}
            />
            {signUpTranslations.number}
          </p>
          <p
            className={`flex items-center gap-2 ${
              formData?.password.match(/[A-Z]/)
                ? "text-mainYellow"
                : "text-[#999999]"
            }`}
          >
            <Image
              src={formData?.password.match(/[A-Z]/) ? yellowTick : tick}
              alt="tick"
              width={16}
              height={16}
            />
            {signUpTranslations.upper}
          </p>
        </div>
        <div className="flex flex-col space-y-1">
          <p
            className={`flex items-center gap-2 ${
              formData?.password.match(/[a-z]/)
                ? "text-mainYellow"
                : "text-[#999999]"
            }`}
          >
            <Image
              src={formData?.password.match(/[a-z]/) ? yellowTick : tick}
              alt="tick"
              width={16}
              height={16}
            />
            {signUpTranslations.lower}
          </p>
          <p
            className={`flex items-center gap-2 ${
              formData?.password.match(/[\W_]/)
                ? "text-mainYellow"
                : "text-[#999999]"
            }`}
          >
            <Image
              src={formData?.password.match(/[\W_]/) ? yellowTick : tick}
              alt="tick"
              width={16}
              height={16}
            />
            {signUpTranslations.special}
          </p>
          <p
            className={`flex items-center gap-2 ${
              formData?.password !== "" &&
              formData?.repeatPassword !== "" &&
              formData?.password === formData?.repeatPassword
                ? "text-mainYellow"
                : "text-[#999999]"
            }`}
          >
            <Image
              src={
                formData?.password !== "" &&
                formData?.repeatPassword !== "" &&
                formData?.password === formData?.repeatPassword
                  ? yellowTick
                  : tick
              }
              alt="tick"
              width={16}
              height={16}
            />
            {signUpTranslations.matching}
          </p>
        </div>
      </div>
      <div className="flex items-start  mt-6 mb-8 gap-2 font-ibmArabic">
        <input
          type="checkbox"
          className="size-4 cursor-pointer md:mt-1"
          onChange={() => setAgreed(!agreed)}
        />
        <p className="text-xs md:text-base text-subGrey leading-4">
          {signUpTranslations.by}{" "}
          <Link href={"/"} className="text-mainYellow underline">
            {signUpTranslations.terms}
          </Link>
        </p>
      </div>
      <button
        disabled={
          !(
            formData?.password.trim().length >= 8 &&
            /\d/.test(formData?.password) &&
            /[A-Z]/.test(formData?.password) &&
            /[a-z]/.test(formData?.password) &&
            /[\W_]/.test(formData?.password) &&
            formData?.password === formData?.repeatPassword &&
            agreed &&
            formData?.firstName !== "" &&
            formData?.lastName !== "" &&
            formData?.phone !== "" &&
            formData?.email !== ""
          )
        }
        type="submit"
        className="bg-mainYellow text-base text-white py-2 px-4 rounded-2xl mt-4 disabled:bg-[#999999]"
      >
        {signUpTranslations.create}
      </button>
      <WebError />
    </form>
  );
};

export default SignUp;
