import React from "react";
import redCircle from "@/assets/redCircle.svg";
import Image from "next/image";
import { useModal } from "src/zustand/useModal";
const WebError = () => {
  const { setOpenModalWeb, openModalWeb, header, text } = useModal();
  return (
    <div
      className={`  px-4 py-6 mt-16
     items-center gap-2
    bg-[#DC35451A] rounded-[20px]
${openModalWeb ? "md:flex" : "hidden"}
 
    `}
    >
      <div
        className="flex justify-center items-center"
        onClick={() => {
          setOpenModalWeb(false);
        }}
      >
        <Image src={redCircle} alt="red-circle" className="cursor-pointer" />
      </div>
      <div className=" ">
        <h1 className="text-lg text-[#dc3545] font-medium font-ibmArabic">
          {header}
        </h1>
        <h2 className=" text-[#dc3545]  font-ibmArabic mt-2">{text}</h2>
      </div>
    </div>
  );
};

export default WebError;
