"use client";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useSendEmailOrPhoneOTP } from "@/apis/OTP/mutations";
import useLanguage from "@/context/useLanguage";
import Image from "next/image";
import eye from "../../assets/signup/eye.png";
import eyeSlash from "../../assets/signup/eye-slash.png";
import WebError from "../common/WebError";
import { useModal } from "src/zustand/useModal";
import useWindowSize from "src/helper/useWindowSize";
import { useRouter } from "next/navigation";
import { useUserData } from "src/zustand/useUserData";
import useOTP from "src/zustand/useOTP";

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const egyptPhoneRegex = /^(?:\+20|0)?1[0125]\d{8}$/;

const Login = () => {
  const { setTimeLeft, setResetTimer } = useOTP();

  const { setPassword } = useUserData();
  const router = useRouter();
  const { width } = useWindowSize();
  const { language, translations } = useLanguage();
  const loginTranslation = translations[language].login;
  const errorMessagesTranslation = translations[language].errorMessage;
  const [showPassword, setShowPassword] = useState(false);
  const { setOpenModal, setModal, setOpenModalWeb } = useModal();
  const [formData, setFormData] = useState({
    phoneOrEmail: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    phoneOrEmail: "",
    password: "",
  });

  const [isTyping, setIsTyping] = useState(false);
  const [label, setLabel] = useState("Phone number or E-mail");
  const [invalidInput, setInvalidInput] = useState(false); // Added state for invalid input
  const { mutateAsync } = useSendEmailOrPhoneOTP();

  useEffect(() => {
    if (typeof window !== "undefined") {
      ReactGA.send(
        "pageview",
        window.location.pathname + window.location.search
      );
    }
  }, []);

  const determineLabel = (input) => {
    const trimmedInput = input.trim();

    if (!trimmedInput) {
      setLabel("Phone number or E-mail");
      return "Phone number or E-mail";
    }

    const isNumbersOnly = /^\d+$/.test(trimmedInput);

    if (!isNumbersOnly) {
      setLabel("Email");
      return "Email";
    } else if (isNumbersOnly) {
      setLabel("Phone");
      return "Phone";
    } else {
      setLabel("Phone number or E-mail");
      return "Phone number or E-mail";
    }
  };

  const validateInput = (input) => {
    if (!isTyping) return; // Skip validation if the user hasn't started typing
    const label = determineLabel(input); // Update label dynamically based on input
    let isValid = true; // Start assuming the input is valid
    if (label === "Email") {
      if (!emailRegex.test(input)) {
        setInvalidInput(true); // Invalid email
        setErrors((prev) => ({
          ...prev,
          phoneOrEmail: errorMessagesTranslation.invalidEmail,
        }));
        isValid = false;
      } else {
        setInvalidInput(false);
        setErrors((prev) => ({ ...prev, phoneOrEmail: "" }));
      }
    } else if (label === "Phone") {
      if (!egyptPhoneRegex.test(input)) {
        setInvalidInput(true); // Invalid phone number
        setErrors((prev) => ({
          ...prev,
          phoneOrEmail: errorMessagesTranslation.invalidPhone,
        }));
        isValid = false;
      } else {
        setInvalidInput(false);
        setErrors((prev) => ({ ...prev, phoneOrEmail: "" }));
      }
    }

    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setIsTyping(true);

    if (name === "phoneOrEmail") {
      determineLabel(value); // Update label dynamically
      validateInput(value); // Validate input
      ReactGA.event({
        category: "User",
        action: "Input Change",
        label: `Entered ${name}: ${value}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    ReactGA.event({
      category: "User",
      action: "Login Attempt",
      label: `User attempted login with ${formData.phoneOrEmail}`,
    });

    const { phoneOrEmail } = formData;
    const isEmail = emailRegex.test(phoneOrEmail);
    const isPhone = egyptPhoneRegex.test(phoneOrEmail);
    let sentData;

    if (isEmail) {
      localStorage.setItem("phoneOrEmailType", "email");
      sentData = {
        credentials_type: "email",
        email: formData?.phoneOrEmail,
        password: formData.password,
      };
    } else if (isPhone) {
      localStorage.setItem("phoneOrEmailType", "phone");
      sentData = {
        credentials_type: "phone",
        phone: formData?.phoneOrEmail.startsWith("0")
          ? formData?.phoneOrEmail.substring(1)
          : formData?.phoneOrEmail,
        countryCode: "20",
        password: formData.password,
      };
    }

    if (!invalidInput) {
      await mutateAsync(sentData)
        .then((res) => {
          setPassword(formData.password);
          setTimeLeft(res?.data?.time);
          localStorage.setItem("phoneOrEmail", formData.phoneOrEmail);
          router.push("/otp-verification");
        })
        .catch((error) => {
          const validationErrors =
            error?.response?.data?.validation || error?.response?.data?.message;

          if (validationErrors) {
            // Extract the first validation error message
            let firstErrorMessage =
              typeof validationErrors === "string"
                ? validationErrors
                : Object.values(validationErrors)?.[0];
            console.log("firstErrorMessage", firstErrorMessage);
            setModal({
              header:
                language === "en" ? "Login unsuccessful" : "فشل تسجيل الدخول",
              text:
                firstErrorMessage || language === "en"
                  ? "An unexpected error occurred"
                  : "حدث خطأ غير متوقع",
              buttonText: "Close",
              type: firstErrorMessage.startsWith("you should wait")
                ? language === "en"
                  ? "waiting"
                  : "انتظار"
                : language === "en"
                ? "error"
                : "خطأ",
            });
            if (width < 768) {
              setOpenModal(true);
            } else {
              setOpenModalWeb(true);
            }
          } else {
            console.error("An unexpected error occurred:", error);
          }
        });
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col relative   pt-8 flex-1 font-ibmArabic"
    >
      <label className="text-subGrey text-xs mb-3">
        {/* {loginTranslation} */}
        {label === "Email"
          ? loginTranslation.email
          : label === "Phone"
          ? loginTranslation.phone
          : loginTranslation.phoneOrEmail}
      </label>
      <input
        style={{
          boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012`,
        }}
        type="text"
        name="phoneOrEmail"
        value={formData.phoneOrEmail}
        onChange={handleChange}
        className={`shadow-sm p-4 rounded-xl bg-white focus:border-mainYellow focus:border mb-4 placeholder:text-[#E6E6E6] ${
          errors.phoneOrEmail ? "border-red-500" : ""
        }`}
        placeholder={`${loginTranslation.enter} ${
          label === "Email"
            ? loginTranslation.email
            : label === "Phone"
            ? loginTranslation.phone
            : loginTranslation.phoneOrEmail
        }`}
      />
      {errors.phoneOrEmail && (
        <div className="text-red-500 text-xs  ">{errors.phoneOrEmail}</div>
      )}
      <div className="relative">
        <label className="text-subGrey text-xs">
          {loginTranslation.password}{" "}
        </label>
        <input
          style={{
            boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012 `,
          }}
          type={showPassword ? "text" : "password"}
          name="password"
          value={formData.password}
          onChange={handleChange}
          className="mt-2 shadow-sm p-4 rounded-xl bg-white focus:border-mainYellow focus:border placeholder:text-[#E6E6E6] w-full"
          placeholder={loginTranslation.enterPassword}
        />
        <span
          className={`
            absolute top-[69%]  transform -translate-y-1/2 cursor-pointer
            ${language === "en" ? "right-4" : "left-4"}
            `}
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <Image
            src={showPassword ? eyeSlash : eye}
            alt="eye"
            width={20}
            height={20}
          />
        </span>
      </div>
      <button
        type="button"
        onClick={() => {
          router.push("/forget-password");
        }}
        className={`
          underline text-xs font-ibmArabic text-[#595959]  mt-2
          ${language === "en" ? "ml-auto" : "mr-auto"}
          `}
      >
        {loginTranslation.forget}
      </button>
      <button
        type="submit"
        className="bg-mainYellow text-base text-white py-3 px-4 rounded-xl mt-16 disabled:bg-[#999999] font-medium font-ibmArabic"
        disabled={invalidInput || formData?.password.length < 8}
      >
        {loginTranslation.login}
      </button>
      <WebError />
    </form>
  );
};

export default Login;
