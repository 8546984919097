"use client";
import React, { useState, useEffect } from "react";
import SwiperPage from "../SwiperPage/SwiperPage";
import backButton from "../../assets/signup/back-button.png";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import sabika from "@/assets/SabikaLogo.svg";
import menu from "@/assets/menu.svg";
import SignUp from "../SignUp/SignUp";
import Login from "../Login/Login";
import RightSidePage from "../common/RightSidePage";
import useLanguage from "src/context/useLanguage";
const Authentication = () => {
  const { language, translations } = useLanguage();
  const loginTranslation = translations[language].login;
  const router = useRouter();
  // useEffect(() => {
  //   if (token?.length > 0) {
  //     router.push("/home");
  //   }
  // }, [router]);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div
      dir={language === "en" ? "ltr" : "rtl"}
      className="flex flex-row bg-white py-16 px-6  font-ibm  md:p-0  mx-auto font-ibmArabic"
    >
      <div className="w-full  md:w-1/2  flex flex-col  md:p-24   ">
        <div className="my-auto">
          <Link href={"/"} className="w-fit hidden md:md">
            <Image src={backButton} alt="backButton" />
          </Link>
          {/* Header Mobile */}
          <div className="flex items-center justify-between mb-8   md:hidden">
            <Link href={"/"} className="w-fit md:hidden">
              <Image src={sabika} alt="backButton" />
            </Link>
            <Image src={menu} alt="menu" className="cursor-pointer" />
          </div>
          {/* Header */}
          <h1 className="mb-2 md:mb-4 md:mt-6 text-2xl text-mainGrey font-semibold font-ibmArabic">
            {loginTranslation.welcome}{" "}
            <span className="text-mainYellow">{loginTranslation.sabika}</span>
          </h1>
          <h2 className="text-[#595959] text-sm md:text-base mb-8 font-ibmArabic">
            {loginTranslation.joinUs}{" "}
            <span className="text-mainYellow">{loginTranslation.seamless}</span>
          </h2>
          {/* Create Account / Login */}

          <div className="bg-backGrey grid grid-cols-2 items-center justify-around rounded-2xl text-center  p-2">
            <button
              onClick={() => setActiveTab(0)}
              className={`
                rounded-lg      cursor-pointer  
                ${
                  activeTab === 0
                    ? " text-white bg-mainYellow   md:p-[0.563rem] py-3"
                    : " text-[#CCCCCC] bg-none"
                }
            `}
            >
              {loginTranslation.login}
            </button>
            <button
              onClick={() => setActiveTab(1)}
              className={`
              rounded-lg      cursor-pointer  
${
  activeTab === 1
    ? " text-white bg-mainYellow   md:p-[0.563rem] py-3 "
    : "text-[#CCCCCC] bg-none"
}
            `}
            >
              {loginTranslation.create}
            </button>
          </div>
          {/* SignUp */}
          {activeTab === 1 ? <SignUp /> : <Login />}
        </div>
      </div>
      <RightSidePage />
    </div>
  );
};

export default Authentication;
