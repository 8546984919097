import { create } from "zustand";

const useOTP = create((set) => ({
  OTPFull: false,
  otp: new Array(4).fill(""),
  wrongOTP: false,
  isSubmit: false,
  resetTimer: false,
  timeLeft: localStorage.getItem("timeLeft") ?? 60,
  setOTPFull: (value) => set({ OTPFull: value }),
  setOtp: (value) => set({ otp: value }),
  setWrongOTP: (value) => set({ wrongOTP: value }),
  setIsSubmit: (value) => set({ isSubmit: value }),
  setResetTimer: (value) => set({ resetTimer: value }),
  setTimeLeft: (value) => set({ timeLeft: value }),
}));

export default useOTP;
